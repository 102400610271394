import axios from '@/libs/api.request'

// 列表
export const articlesPage = (info) => {
  return axios.request({
    url: 'article/page',
    data: info,
    method: 'post',
  })
}
// 修改
export const articlesUpdate = (info) => {
  return axios.request({
    url: 'article/update',
    data: info,
    method: 'post',
  })
}
// 添加
export const articlesAdd = (info) => {
  return axios.request({
    url: 'article/add',
    data: info,
    method: 'post',
  })
}
// 删除
export const articlesDel = (info) => {
  return axios.request({
    url: 'article/del',
    data: info,
    method: 'post',
  })
}
// 查
export const articlesGet = (info) => {
  return axios.request({
    url: 'article/get',
    data: info,
    method: 'post',
  })
}