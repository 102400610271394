<template>
  <Modal v-model="open" :title="title">
    <Form ref="formItem" :model="formItem" :label-width="80" :rules="ruleValidate">
      <FormItem label="地址" prop="url">
        <Input v-model="formItem.url" placeholder="请输入文章地址" clearable />
      </FormItem>
      <FormItem label="索引" prop="index">
        <Input v-model="formItem.index" placeholder="请输入文章索引" clearable />
      </FormItem>
    </Form>
    <div slot="footer" style="text-align: center">
      <Button @click="cancel('formItem')">关闭</Button>
      <Button type="primary" :loading="loading" @click="ok('formItem')">
        提交
      </Button>
    </div>
  </Modal>
</template>
<script>
import { articlesAdd, articlesGet, articlesUpdate } from '@/api/articles'
export default {
  name: 'articleAddUpdate',
  data() {
    return {
      formItem: {
        url: '',
        index: '',
      },
      ruleValidate: {
        url: [{ required: true, message: '请输入', trigger: 'blur' }],
        index: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      open: false,
      title: '固定文章添加',
      loading: false,
    }
  },
  methods: {
    getarticle(_id) {
      articlesGet({ _id }).then(res => {
        this.formItem = { ...res.data.data }
      })
    },
    init(params) {
      if (params) {
        this.title = '固定文章编辑'
        this.getarticle(params._id)
      } else {
        this.title = '固定文章添加'
      }
      this.open = true
    },
    ok(name) {
      this.$refs[name].validate((valid) => {
        if (!valid) return
        this.loading = true
        // const postData = JSON.parse(JSON.stringify(this.formItem))
        if (this.formItem._id) {
          articlesUpdate(this.formItem)
            .then((res) => {
              this.loading = false
              if (res.data.code === 200) {
                this.$Message.success('固定文章修改成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.loading = false
              this.$Message.error(err)
            })
        } else {
          articlesAdd(this.formItem)
            .then((res) => {
              this.loading = false
              if (res.data.code === 200) {
                this.$Message.success('固定文章添加成功')
                this.cancel('formItem')
                this.$emit('reload')
              } else {
                this.$Message.error(res.data.msg)
              }
            })
            .catch((err) => {
              this.loading = false
              this.$Message.error(err)
            })
        }
      })
    },
    cancel(name) {
      this.formItem = {
        url: '',
        index: '',
      }
      this.$refs[name].resetFields()
      this.open = false
    },
  },
  mounted() { },
}
</script>
